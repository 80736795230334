body {
    font-family: 'Calibri', Arial;
    background-color: white;
    margin: 0;
    font-weight: 400;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.terms-container {
  background-color: #fff;
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 2vw;
  padding-right: 2vw;
  border-radius: 8px;
  font-size: 8px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90vw;
  height: fit-content;
  text-align: center;
  margin-top: 16vh !important;
  margin-bottom: 4vh;
}
.welcome-container {
  background-color: #fff;
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 2vw;
  padding-right: 2vw;
  border-radius: 8px;
  font-size: 12px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 60vw;
  height: fit-content;
  text-align: center;
  margin-top: 16vh !important;
  margin-bottom: 4vh;
}
.main{
  padding-top: 10vh;
}
h1 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.terms-text {
  font-size: 12px;
  font-weight: regular;
  color: #1d1d1d;
  font-family: "Calibri";
  line-height: 1.1rem;
  margin-bottom: 12px;
  text-align: left;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
}

.checkbox-container input {
  margin-right: 10px;
}

.next-button {
  width: 10%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  display: block;
  border-radius: 4px;
  float: right  ;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.next-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.next-button:enabled:hover {
  background-color: #0056b3;
}
.txt-welcome{
    text-align: left;
    line-height: 1.2rem;
    font-size: 14px;
}

.row-btn{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.login-button-google {
    border: 1px solid #34A853;
    width: 95%;
    margin-right: 10px;
    padding: 10px;
    border-radius: 4px;
    color: #34A853;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-google img {
    margin-right: 8px;
  }
  
  .login-button-google:enabled:hover {
    background-color:#34A853;
    color: white;
  }
  
  .login-button-outlook {
    border: 1px solid #25A5E8;
    width: 95%;
    padding: 10px;
    border-radius: 4px;
    color: #25A5E8;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-outlook img {
    margin-right: 8px;
  }
  
  .login-button-outlook:enabled:hover {
    background-color:#25A5E8;
    color: white;
  }

  .login-button-yahoo {
    width: 95%;
    padding: 10px;
    border: 1px solid #5F01D1;
    border-radius: 4px;
    color: #5F01D1;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-yahoo img {
    margin-right: 8px;
  }
  
  .login-button-yahoo:enabled:hover {
    background-color:#9239ff;
    color: white;
  }

  .login-button-yahoo.disabled, .login-button-yahoo.disabled,  .login-button-yahoo.disabled:hover, .login-button-outlook.disabled, .login-button-outlook.disabled:hover, .login-button-google.disabled, .login-button-google.disabled:hover {
    background-color: #cccccc;
    border: 1px solid #434343;
    color: #434343;
    cursor: not-allowed;
  }


  @media only screen and (max-width: 740px) {
    .row-btn{
        display: block;
        width: 100%;
    }
    .login-button-outlook {
        width: 100%;
        padding: 10px;
        border: 1px solid #25A5E8;
        border-radius: 4px; 
        color: #25A5E8;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        cursor: pointer;
        margin-top: 2vh;
        margin-bottom: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .welcome-container{
        width: 80%;
        margin: auto;
        margin-top: 0 !important;
      }
      .login-button-google{
        padding: 18px !important;
      }
      .terms-container{
        width: 90%;
        padding: 6px;
        margin: auto;
        display: block;
      }
      .next-button{
        display: block;
        width: 30%;
      }
      .checkbox-container{
        display: block;
      }
      .terms-text{
        font-size: 11px;
      }
  }