.dashboard {
  width: 100vw;
  height: 100vh;
  /* background-color: palevioletred; */
  overflow-x: hidden;
  display: flex;
}

::-webkit-scrollbar {
width: 8px;
}

::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background: rgb(121, 121, 121); 
border-radius: 10px;
}


.sidebar {
  max-width: 18vw;
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px;
}

.logo {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 10px;
}

.sidebar-btn{
  background-color: #113B58;
  border: none;
  color: white;
  width: 100%;
  border-radius: 30px;
  font-family: 'Calibri';
  font-size: 14px;
  font-weight: regular;
  padding: 12px;
  margin-bottom: 10px;
  text-align: center !important;
  cursor: pointer;
  justify-content: space-between;
}

.searchbar {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #899CA9;
  padding-right: 3px;
  height: auto;
}
.group-btn{
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-family: 'Calibri';
  padding: 5px;
  color: white;
}
.btngrpbox
{
  min-height: 49%;
  max-height: 49%;
}
.btngroup{
  background-color: #878787;
  overflow-y: scroll;
  max-height: 69%;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 5px;
}
.searchbar input {
  border: none;
  outline: none;
  width: 12vw;
  border-radius: 4px;
  background-color: #899CA9;
  color: #0D2A3E;
  flex: 1;
  padding: 12px;
}
::placeholder {
  color: #0D2A3E;
  font-family: "Calibri";
  font-weight: 500;
}
.search-icon {
  color: #0D2A3E;
  margin-right: 3px;
  width: 14px;
  z-index: 999;
}

.sub-links {
  padding-left: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.sub-links a {
  color: white;
  text-decoration: none;
  padding: 5px 0;
}

.bottom-options {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 18vw;
}
.active{
  color: #0D2A3E;
}
.bottom-btn {
  position: relative;
  background-color: transparent;
  border: none;
  color: #0D2A3E;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 8px;
  align-items: center;
}
.icon-boxm{
  width: 160px;
  height: 160px;
  align-items: center;
  display: flex;
  margin: 29px;
  background-color: #F2F2F2;
  border-radius: 12px;
}
.icon-circle{
  background-color: #D9D9D9; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  text-align: center;
  width: 60px;
  height: 50px;
  padding-top: 15px;
}
.flex-icon{
  display: flex;
}
.icon-box{
  width: 100%;
  font-size: 28px;
  color: #939393;
  text-align: center;
}
.topbar {
  display: flex;
  overflow-x: hidden;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 80vw;
}

.topbar-btn {
  background-color: transparent;
  border: none;
  color: #0D2A3E;
  cursor: pointer;
  font-family: 'Calibri';
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 4px solid #0D2A3E;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.content {
  width: fit-content;
  flex: 1;
  margin-left: 30%;
  margin-right: auto;
  padding: 0px;
}

.icon-grid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

/** Setting **/
.set-cont{    
  height: auto;
  margin-left: auto;
  margin-right: auto;
  width: 84%;
  padding: 3px;
}


.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tab {
  width: 15vw;
  position: relative;
  padding: 12px 15px;
  cursor: pointer;
  font-size: 12;
  font-weight: medium;
  font-family: 'Calibri';
  background-color: #f0f0f0;
  box-shadow: 4px 5px 8px rgb(67, 67, 67) !important;
  border: none;
  border-bottom: none;
  font-weight: bold;
  color: #000000;
  margin-right: -12px;
  /* clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%); */
  border-radius: 2px 10px 0 0;
  transition: background-color 0.3s;
}
/* .tab-wrap{
  position: relative;
  filter: drop-shadow(4px 6px 3px rgba(50, 50, 0, 0.5));
}
 */
.tab.active {
  background-color: #C3C3C3;
}

.tab-content {
  margin-top: -9px;
  border: none;
  z-index: 79;
  position: relative;
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 0 0 4px 4px;
  min-height: 54vh;
  height: fit-content;
}

.tab:not(.active):hover {
  background-color: #ddd;
}

.set-head{
  color: #000000;
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: 'Calibri';
  font-weight: bold;
  text-align: center;
}

.input-set{
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

.input-set input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;  
  font-family: "Calibri";
  width: 45vw;
  font-size: 12px;
}
.add-new-subscription{
  width: 100%;
  text-align: center;
}
.input-set label {
  display: flex;
  width: 5vw;
  margin-bottom: 5px;
  text-align: center  ;
  font-weight: 500;
  font-size: 12px;
  font-family: "Calibri";
  color: black;
}
.prof-inp, .half-inp{
  display: flex;
}
.prof{
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 4px solid #0D2A3E;
}
.inp{
  flex: 1;
}
.half-inp input{
width: 17vw;
}
.half-inp select{
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;  
font-family: "Calibri";
font-size: 12px;
width: 18.7vw;
}
.ts{
margin-left: 6.5%;
}
.save-btn{
width: 20%;
padding: 15px;
background-color: #0D2A3E;
color: white;
font-family: 'Calibri';
font-size: 13px;
border: none;
border-radius: 24px;
font-weight: 500;
}
.s-butn{
width: 100%;
margin-left: auto;
padding-top: 20px;
text-align: center;
margin-right: auto;
}

/** Configure Tab **/
.configure-subscription-container {
padding: 20px;
font-family: 'Calibri', 'Poppins';
}

.label {
font-size: 14px;
font-weight: 500;
margin-bottom: 10px;
}

.form-group {
margin-bottom: 20px;
display: flex;
}

.email-select-container {
display: flex;
align-items: center;
margin-left: 10px;
width: 50%; 
}

.dropdown {
padding: 8px;
font-size: 14px;
margin-right: 10px;
border: 1px solid #ccc;
border-radius: 4px;
width: 88%;
}

.plus-button {
padding: 5px 12px;
font-size: 18px;
background-color: #0D2A3E;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.input-field {
width: 51.2%;
padding: 8px;
margin-left: 10px;
font-size: 14px;
border: 1px solid #ccc;
border-radius: 4px;
}

.button-group {
display: flex;
margin-top: 20px;
margin-left: auto;
margin-right: auto;
width: 36%;
text-align: center;
}

.manage-email-button, .save-button {
padding: 15px 35px;
width: fit-content;
margin-right: 20px;
background-color: #0D2A3E;
color: white;
font-family: 'Calibri';
font-size: 13px;
border: none;
border-radius: 24px;
font-weight: 500;
}

.txt-conf{
font-size: 14px;
font-family: 'Calibri';
font-weight: medium;
margin-bottom: 20px;
}

/** Security Tab **/
.security-tab-container {
padding: 20px;
font-family: 'Calibri', 'Poppins';
align-items: center;
}

.auth-methods {
margin-top: 5px;
}

.auth-option {
display: flex;
align-items: top;
background-color: #D9D9D9;
border: 1px solid black;
padding: 15px;
accent-color: #0D2A3E;
margin-bottom: 10px;
}

.radio-button {
margin-right: 10px;
}
.authlab{
display: block;
}
.auth-label {
font-family: 'Calibri';
font-size: 14px;
font-weight: bold;
margin: 0;
padding: 0;
}
.auth-txt{
font-family: 'Calibri';
font-size: 13px;
font-weight: 500;
margin: 0;
padding: 0;
}
.pass-form{
text-align: center;
}
.factor-cont{
width: 62%;
height: max-content;
margin-left: auto;
margin-right: auto;
}

.toggle{
  display: none;
}
.toggle-button, .sidebarmob{
  display: none;
}

/* Add new Subscription **/
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #115B0F;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded slider */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}

.hed{
  display: flex;
  justify-content: space-between;
  width: 98%;
  font-family: 'Calibri';
  margin: auto;
}
.subs-form input{
  width: 70%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;  
  font-family: "Calibri";
}
.hed label, .subs-form label{
  font-size: 12px;
}

#emailAccount{
  width: 68%;
}

.gbf{
  width: 20%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.gf{
  text-wrap: nowrap;
  width: fit-content;
  display: block;
}
.radiothree{
  display: flex;
  justify-content: space-around;
  justify-items: left;
  width: 100%;
}
.radiothree label{
  display: flex;
  padding: 10px;
  text-align: left !important;
  width: max-content;
  text-wrap: nowrap;
}
.sdss{
  width: 100%;
  display: block;
}

.subs-form{
  background-color: #F5F5F5;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  margin: auto;
}

.filter-box, .action-box{
  background-color: #E8E8E8;
  padding: 12px;
  padding-top: 0px !important;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}
.filter-row{
  justify-content: space-between;
  margin-top: 15px;
  display: flex;
}

.filter-box input,  .filter-box select{
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;  
  font-family: "Calibri";
}
.action-box select{
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;  
  margin-top: 15px;
  font-family: "Calibri";
}
.filter-box button{
  background-color: #0D2A3E;
  color: white;
  border-radius: 10px;
  width: 5%;
  font-family: 'Calibri';
  font-size: 14px;
  font-weight: 600;
  border: none;
}
.form-actions{
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}
.sdssw{
  display: flex;
  width: 100%;
}
.subs-form label{
  text-wrap: nowrap;
}
@media (max-width: 788px) {
  .input-set label {
    width: fit-content;
  }
  .dashboard {
    display: flex;
    position: relative;
  }

  .topbar{
    min-width: 87vw;
    margin-left: 10%;
    width: fit-content;
    justify-content: space-between;
  }
  .topbar-btn{
    font-size: 0.4rem;
  }
  .content {
    margin: 0;
    padding: 0;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .flex-icon{
    display: flex;
  }
  .icon-boxm{
    width: 120px;
    height: 120px;
  }
  .profile-pic {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #0D2A3E;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img{
    width: 25px;
    height: 25px;
  }

  .bottom-options {
    display: flex;
    position: relative;
  }
  .bottom-btn {
    background-color: transparent;
    border: none;
    color: #0D2A3E;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    padding: 8px;
    align-items: center;
  }

  .toggle-button {
    display: block;
    font-size: 10px;
    position: fixed;
    top: 10px;
    left: 5px;
    color: white;
    padding: 8px;
    border-radius: 24px;
    background-color: transparent;
    border: none;
    background-color: #0D2A3E;
    z-index: 1001;
    cursor: pointer;
  }
  .fjf{
    height: 85%;
  }
  
  /* Sidebar styles */
  .sidebar {
    /* display: block; */
    position: fixed;
    top: 0;
    justify-content: space-around;
    padding: 12px;
    left: -70vw; /* Start off-screen */
    min-width: 60vw !important;
    height: 100%;
    color: white;
    transition: left 0.3s ease;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .sidebar.open {
    left: 0; /* Slide in */
  }
  
  .sidebar-content {
    padding: 20px;
  }
  
  .sidebar-content h2 {
    margin: 0 0 20px;
    font-size: 20px;
  }
  
  .sidebar-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-content ul li {
    margin: 15px 0;
    cursor: pointer;
  }
  .sidebar-btn{
    background-color: #0D2A3E;
    color: white;
    padding: 12px;
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-radius: 24px;
  }
  
  /* Overlay styles */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .input-set, .prof-inp{
    width: 100%;
    display: block;
  }
  .half-inp{
    width: 100%;
    display: block;
    margin-left: 0;
  }
  .ts{
    margin-left: 0;
  }
  .half-inp input, .half-inp select {
    width: 90%;
    display: block;
  }
  .input-set input  {
    width: 90%;
    display: block;
  }
  .prof{
    display: none;
  }
  .save-btn{
    width: 50%;
  }
  .main-content{
    width: 100%;
  }
  .tab-content{
    width: 100%;
    min-height: max-content;
    padding: 12px;
  }
  .tab {
    width: fit-content;
    position: relative;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 8px;
    font-weight: medium;
    font-family: 'Calibri';
    background-color: #f0f0f0;
    box-shadow: 4px 5px 8px rgb(67, 67, 67) !important;
    border: none;
    color: #000000;
    margin-right: -12px;
    /* clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%); */
    border-radius: 2px 10px 0 0;
    transition: background-color 0.3s;
  }
  .form-group{
    display: block;
  }
  .email-select-container{
    width: 100%;
  }
  .form-group input{
    width: 100%;
  }
  .button-group{
    width: 100%;
  }
  .factor-cont{
    width: 90%;
  }
  .configure-subscription-container select{
    max-width: 250px;
  }
  .configure-subscription-container input{
    max-width: 280px;
  }
  .hed{
    width: 100%;
    display: flex;
  }
  .hed div{
    min-width: fit-content;
  }
  .esx{
    min-width: 99%;
    display: flex;
  }
  .subs-form input{
    width: 95%;
  }
  .sdssw{
    display: flex;
    width: 100%;
  }
  .gbf{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .gf{
    text-wrap: nowrap;
    width: fit-content;
    display: block;
  }
  .radiothree{
    display: block;
  }

  .filter-box, .action-box{
    background-color: #E8E8E8;
    padding: 8px;
    padding-top: 0px !important;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
  }
  .filter-row{
    justify-content: space-between;
    margin-top: 15px;
    display: flex;
  }
  
  .filter-box input,  .filter-box select{
    padding: 6px;
    margin-right: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;  
    font-family: "Calibri";
  }
  .action-box select{
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;  
    margin-top: 15px;
    margin-right: 8px;
    font-family: "Calibri";
  }
  .filter-box button{
    background-color: #0D2A3E;
    color: white;
    border-radius: 10px;
    width: 16%;
    font-family: 'Calibri';
    margin-left: -4px;
    font-size: 14px;
    font-weight: 600;
    border: none;
  }
}