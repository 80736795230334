.Imappopup{
    background-color: white;
    width: 55%;
    padding: 20px;
    font-size: 12px;
    font-family: 'Calibri';
    line-height: 0.2em;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.form-group{
    margin: 0;
}
.Imappopup input, .Imappopup select{
    flex: 1;
    padding: 8px;
    line-height: 0.4rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0;
    margin-bottom: 0;
    font-family: 'Calibri', 'Poppins';
    font-size: 14px;
}

.Imappopup label{
    width: 120px;
    margin: 0;
    font-family: 'Calibri', 'Poppins';
    font-weight: 500;
    font-size: 14px;
}

.row-flx{
    display: flex;
    width: 100%;
}
.row-flx input, .row-flx select{
    width: 225px;
}
.sd{
    width: 100%;   
}   
.sd label{
    margin-left: 10%;
    margin-right: 0;
    width: 10%;
}
.sds{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 740px) {
    .Imappopup{
     width: 85%;
     padding: 10px;
    }
    .Imappopup input, .Imappopup select{
        margin-top: 9px;
        width: 90% !important;
    }
    .Imappopup label{
        display: block;
        text-align: left !important;
    }
    .row-flx{
        justify-content: space-between;
    }
    .row-flx input, .row-flx select{
        display: block;        
        width: 100%;
    }
    .sd, .jd{
        display: flex;
        min-width: 49% !important;
    }
    .btn-group{
        width: 100%;
    }
    .add-button, .remove-button{
        font-size: 10px;
    }
}
