.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .set-headp{
    text-align: center;
    font-size: 18px;
    font-family: 'Calibri';
    font-weight: 600;
    color: #0D2A3E;
  }
  .linkview{
    color: #0D2A3E;
    text-decoration: none;
  }
  .popup {
    background-color: white;
    width: 55%;
    height: max-content;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .popup-header h2 {
    font-family: 'Calibri', 'Poppins';
    font-weight: bold;
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form-group label {
    font-family: 'Calibri', 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: black;
    margin: 0;
  }
  
  .dropdown {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Calibri', 'Poppins';
    font-size: 14px;
  }
  
  .select-button {
    padding: 8px 16px;
    background-color: #0D2A3E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Calibri', 'Poppins';
  }
  .email-config-box {
    padding: 15px;
    border: 2px dashed #4D4D4D;
    border-radius: 10px;
    background-color: #f9f9f9;
    height: 50%;
  }
  
  .email-config-box h3 {
    font-family: 'Calibri', 'Poppins';
    font-weight: bold;
    margin-top: 0;
  }
  
  .email-config-box p {
    font-family: 'Calibri', 'Poppins';
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    margin: 5px 0;
    color: black;
  }
  .divider {
    font-size: 12px;
    margin-top: 16px;
    display: flex;
    color: #6A6A6A;
    align-items: center;
  }
  
  .divider::before, .divider::after {
    flex: 1;
    content: '';
    padding: 1px;
    background-color: #C1C1C1;
    margin: 5px;
  }
  .btn-group{
    align-items: center;
    display: flex;
    width: 53%;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .add-button{
    padding: 15px 30px;
    width: fit-content;
    background-color: #0D2A3E;
    color: white;
    font-family: 'Calibri';
    font-size: 13px;
    border: none;
    border-radius: 24px;
    font-weight: 500;
  }
  .cancel-button{
    padding: 15px 30px;
    width: fit-content;
    border: 1px solid #0D2A3E;
    margin-right: 20px;
    color: #0D2A3E;
    font-family: 'Calibri';
    font-size: 13px;
    border-radius: 24px;
    font-weight: 500;
  }
  .remove-button{
    padding: 15px 30px;
    width: fit-content;
    background-color: #DD0000;
    color: white;
    font-family: 'Calibri';
    font-size: 13px;
    border: none;
    border-radius: 24px;
    font-weight: 500;
  }
  .emailbtn{
    display: flex;
    width: 82%;
  }

  @media (max-width: 788px) {
    .emailbtn{
      margin-bottom: 8px;
      width: 85%;
      display: flex;
    }
  }