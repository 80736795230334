body {
    font-family: 'Calibri';
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    height: 58vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 21vw;
    height: fit-content;
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #1c1b1b;
  }

  .input-group input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  
  .otp-input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .otp-input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #1c1b1b;
  }
  .otp-input-group input {
    width: 75%;
    margin-right: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .login-button-google {
    width: 100%;
    padding: 10px;
    border: 1px solid #34A853;
    border-radius: 4px;
    color: #34A853;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-google img {
    margin-right: 8px;
  }
  
  .login-button-google:hover {
    background-color:#34A853;
    color: white;
  }
  
  .login-button-outlook {
    width: 100%;
    padding: 10px;
    border: 1px solid #25A5E8;
    border-radius: 4px;
    color: #25A5E8;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-outlook img {
    margin-right: 8px;
  }
  
  .login-button-outlook:hover {
    background-color:#25A5E8;
    color: white;
  }

  .login-button-yahoo {
    width: 100%;
    padding: 10px;
    border: 1px solid #5F01D1;
    border-radius: 4px;
    color: #5F01D1;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button-yahoo img {
    margin-right: 8px;
  }
  
  .login-button-yahoo:hover {
    background-color:#9239ff;
    color: white;
  }

@media (max-width: 788px) {
  .login-container{
    width: 90%;
  }
}