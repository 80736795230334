body {
    font-family: 'Calibri', Arial;
    background-color: white !important;
    margin: 0;
    font-weight: 400;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
    color: black;
  }
  
  .login-form .input-group {
    margin-bottom: 15px;
    text-align: left;  
  }
  
  .login-form .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Calibri";
    color: black;
  }
  
  .login-form .input-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;  
    font-family: "Calibri";
    width: 25vw;
    font-size: 14px;
  }
  .bottomtxt{
    text-align: center;
    color: #007bff;
    font-weight: 300;
  }
  .bottomtxt a{
    color: #007bff;
    font-weight: 500;
  }
  .butn{
    display: block;
    float: right;
    width: 100%;
    margin-bottom: 40px;
  }
  .App-logo{
    width: 100%;
    display: block;
    text-align: center;
  }
  .login-button {
    width: 40%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-family: "Calibri";
    float: right;
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  @media only screen and (max-width: 740px) {
    .login-form .input-group input {
      width: 80vw;
    }
  }