  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form-group label {
    font-family: 'Calibri', 'Poppins';
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
  
  .dropdown {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Calibri', 'Poppins';
    font-size: 14px;
  }
  
  .select-button {
    padding: 8px 16px;
    background-color: #0D2A3E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Calibri', 'Poppins';
  }
  
  /* Form styling */
  .email-form .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  
  .email-form label {
    width: 120px;
    font-family: 'Calibri', 'Poppins';
    font-weight: 500;
    font-size: 14px;
  }
  
  .email-form input {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Calibri', 'Poppins';
    font-size: 14px;
  }
  
  .otp-button {
    padding: 8px 16px;
    background-color: #f39c12;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Calibri', 'Poppins';
  }
  
  /* Submit button styling */
  .submit-button {
    display: flex;
    width: 40%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Gmail and Outlook specific styles */
  .submit-button.gmail {
    padding: 10px;
    border: 1px solid #34A853;
    border-radius: 4px;
    color: #34A853;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button.gmail:hover {
    background-color: #34A853;
    color: white;
  }

  
  .submit-button.outlook {
    padding: 10px;
    border: 1px solid #25A5E8;
    border-radius: 4px;
    color: #25A5E8;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button.outlook:hover {
    background-color: #25A5E8;;
    color: white;
  }

  .submit-button.yahoo {
    padding: 10px;
    border: 1px solid #5F01D1;
    border-radius: 4px;
    color: #5F01D1;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button.yahoo:hover {
    background-color: #5F01D1;
    color: white;
  }

  .submit-button.hotmail {
    padding: 10px;
    border: 1px solid #25A5E8;
    border-radius: 4px;
    color: #25A5E8;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button.hotmail:hover {
    background-color: #25A5E8;;
    color: white;
  }

  .submit-button.imap {
    padding: 10px;
    border: 1px solid #25A5E8;
    border-radius: 4px;
    color: #25A5E8;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button.imap:hover {
    background-color: #25A5E8;;
    color: white;
  }
  
  .submit-button svg {
    margin-right: 10px;
  }

  .dropbtn{
    width: 90%;
  }
  @media only screen and (max-width: 740px) {

    .popup-content {
      display: block;
    }
    .dropdown {
      width: 75% !important;
    }
    .form-group label {
      text-align: left !important;
      display: block;
    }
    .email-form{
      display: block;
      margin: 0;
      left: 0;
      padding: 0;
    }
    .popup{
      width: 80%; 
      height: max-content;
    }
    .email-form input {   
      min-width: 70%;
    }
    .email-form label{
      width: 40%;
      text-align: left;
    }
    .form-group{
      display: block;
      min-width: 340px;
      text-align: left;
    }
    .otpf{
      min-width: 60% !important;
    }
    .otp-button {
      font-size: 10px;
    }
    .dropbtn{
      display: flex;
      width: 85%;
      margin-bottom: 12px;
    }
    .fielddiv{
      min-width: 100%;
    }
  }